@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    .text-shadow {
      text-shadow: 0 0px 3px rgba(0, 0, 0, 0.5);
    }
    .text-shadow-md {
      text-shadow: 0 0px 3px rgba(0, 0, 0, 0.5);
    }
    .text-shadow-lg {
      text-shadow: 0 0px 3px rgba(0, 0, 0, 0.5);
    }
    .text-shadow-none {
      text-shadow: none;
    }

    .drop-shadow {
      filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.4));
    }

    .section-list-grid {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));
    }

    .section-list-grid-md {
      display: grid;
      grid-gap: 1.5rem;
      grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
    }
  }

  .scrollbar {
    scrollbar-color: #db1931 #404048;
    scrollbar-width: thin;
  }
  .scrollbar::-webkit-scrollbar {
    width: 8px;
  }
  .scrollbar::-webkit-scrollbar-track {
    background-color: #404048;
    border-radius: 0.375rem;
  }
  .scrollbar::-webkit-scrollbar-thumb {
    background-color: #db1931;
    border-radius: 0.375rem;
  }

  .hide-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .hide-scroll-bar::-webkit-scrollbar {
    display: none;
  }
}
