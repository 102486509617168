.DayPicker-Day {
  @apply rounded-md;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  @apply text-black;
}

.DayPicker-Day--disabled {
  @apply text-gray-500;
}
